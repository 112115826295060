$(document).ready(function() {
  // eventosSliderHome();
  eventosSliderHomePruebas();
});

// BTN CTA MAIN
const btnCtaMainHomeTemporada = document.querySelector('#btn-cta-main-home-temporada');
const btnCtaMainHome = document.querySelector('#btn-cta-main-home');
const targetForm = document.querySelector('#ancla-seccion-form');

if(btnCtaMainHomeTemporada){
  btnCtaMainHomeTemporada.addEventListener('click', () => {
    // location.href = "#ancla-section-form";
    // location.href = "https://bit.ly/3sJr9ew";
    location.href = "/aliados.php";
  });
}

if(btnCtaMainHome && targetForm){
  btnCtaMainHome.addEventListener('click', () => {
    targetForm.scrollIntoView({ behavior: 'smooth', block: 'start'});
  });
}

// SLIDES HOME TEMPORADA
// const eventosSliderHome = () => {
//   const contenedorBg = document.querySelector('.main-home.temporada');

//   const arrowLeftControl = document.querySelector('#arrow-carousel-home-left');
//   const arrowRightControl = document.querySelector('#arrow-carousel-home-right');

//   // SLIDER SCRIPTING
//   // $('#carouselPruebasHome').on('slid.bs.carousel', (event) => {
//   $('#carouselMainHome').on('slide.bs.carousel', (event) => {
//     if(event.to === 0){
//       contenedorBg.classList.add('temporada');

//       arrowLeftControl.classList.add('temporada');

//       arrowRightControl.classList.add('temporada');
//     }
//     else{
//       contenedorBg.classList.remove('temporada');

//       arrowLeftControl.classList.remove('temporada');

//       arrowRightControl.classList.remove('temporada');
//     }
//   });
// }


// SLIDES HOME PRUEBAS
const eventosSliderHomePruebas = () => {
  // const contenedorBg = document.querySelector('.section-home-02');

  const arrowLeftControl = document.querySelector('#arrow-carousel-pruebas-left');
  const arrowRightControl = document.querySelector('#arrow-carousel-pruebas-right');

  // SLIDER SCRIPTING
  // $('#carouselPruebasHome').on('slid.bs.carousel', (event) => {
  $('#carouselPruebasHome').on('slide.bs.carousel', (event) => {
    if(event.to === 0){
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-01');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-01');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-01');
    }
    else if(event.to === 1){
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-02');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-02');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-02');
    }
    else if(event.to === 2){
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-03');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-03');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-03');
    }
    else if(event.to === 3){
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-04');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-04');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-04');
    }
    else if(event.to === 4){
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-05');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-05');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-05');
    }
    // else if(event.to === 5){
    else{
      // contenedorBg.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      // contenedorBg.classList.add('prueba-06');

      arrowLeftControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowLeftControl.classList.add('prueba-06');

      arrowRightControl.classList.remove('prueba-01', 'prueba-02', 'prueba-03', 'prueba-04', 'prueba-05', 'prueba-06');
      arrowRightControl.classList.add('prueba-06');
    }
  });
}